import { MANAGE_CHANNELS, MANAGE_VIDEOS, OPEN_VIDEO_MANAGER_IN_BM, LIVE_STREAM, ADD_VIDEO_ELEMENT, OPEN_CHANNELS_TAB, OPEN_LIVE_STREAM_TAB, OPEN_VIDEO_SECTION_IN_ADD_PANEL, UPGRADE_TEXT, UPGRADE_LINK_TEXT, } from './constants';
export var getWixVideoAppManifest = function (translate) {
    return {
        appDescriptor: {
            mainActions: [
                {
                    title: translate(MANAGE_VIDEOS),
                    actionId: OPEN_VIDEO_MANAGER_IN_BM,
                    icon: 'appManager_settingsAction',
                },
            ],
            customActions: [
                {
                    title: translate(ADD_VIDEO_ELEMENT),
                    actionId: OPEN_VIDEO_SECTION_IN_ADD_PANEL,
                    icon: 'appManager_addElementsAction',
                    type: 'editorActions',
                },
                {
                    title: translate(MANAGE_CHANNELS),
                    actionId: OPEN_CHANNELS_TAB,
                    icon: 'appManager_settingsAction',
                    type: 'dashboard',
                },
                {
                    title: translate(LIVE_STREAM),
                    actionId: OPEN_LIVE_STREAM_TAB,
                    icon: 'appManager_settingsAction',
                    type: 'dashboard',
                },
            ],
            defaultActions: {
                upgrade: {
                    upgradeType: 'SITE_UPGRADE',
                    upgradeText: translate(UPGRADE_TEXT) + ' ',
                    upgradeLinkText: translate(UPGRADE_LINK_TEXT),
                },
            },
        },
    };
};
